// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-archive-tsx": () => import("./../../../src/pages/archive.tsx" /* webpackChunkName: "component---src-pages-archive-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-talks-tsx": () => import("./../../../src/pages/talks.tsx" /* webpackChunkName: "component---src-pages-talks-tsx" */),
  "component---src-templates-pagination-tsx": () => import("./../../../src/templates/pagination.tsx" /* webpackChunkName: "component---src-templates-pagination-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-projects-tsx": () => import("./../../../src/templates/projects.tsx" /* webpackChunkName: "component---src-templates-projects-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../../../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */)
}

